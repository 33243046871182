<template>
  <div class="alert alert-warning">กำลังออกจากระบบ...กรุณารอซักครู่</div>
</template>
<script>
import Auth from '@/helpers/auth'

export default {
  name: "Logout",
  mounted() {
    Auth.logout()
  }
}
</script>
